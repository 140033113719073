import React, {useState} from 'react';
import {Button, ButtonGroup} from 'react-bootstrap'; // React Bootstrap

function Pagination({maxPages, onPageChange, rows, handleExport}) {
    const [currentPage, setCurrentPage] = useState(1);
    const maxVisibleButtons = 5;

    // Calculate the start and end page for the visible buttons
    const startPage = Math.max(1, Math.min(currentPage - Math.floor(maxVisibleButtons / 2), maxPages - maxVisibleButtons + 1));
    // const endPage = Math.min(maxPages, startPage + maxVisibleButtons - 1);

    // Handle page change and notify parent component
    const handlePageChange = (page) => {
        setCurrentPage(page);
        onPageChange(page);  // Return the page number to the parent component
    };

    // Handle next and back
    const handleNext = () => {
        if (currentPage < maxPages) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            onPageChange(nextPage);  // Return the next page number
        }
    };

    const handleBack = () => {
        if (currentPage > 1) {
            const prevPage = currentPage - 1;
            setCurrentPage(prevPage);
            onPageChange(prevPage);  // Return the previous page number
        }
    };

    const handleFirst = () => {
        setCurrentPage(1);
        onPageChange(1);
    };

    const handleLast = () => {
        setCurrentPage(maxPages);
        onPageChange(maxPages);
    };


    // Generate array of visible page numbers
    const visiblePages = Array.from({length: maxVisibleButtons}, (_, i) => startPage + i);

    return (
        <div style={{display: 'flex', justifyContent: 'center', margin: ''}}>
            <ButtonGroup>
                <Button className='nav-button mx-1' onClick={handleFirst} disabled={currentPage === 1}>
                    FIRST
                </Button>

                {/* Back Button */}
                <Button className='nav-button mx-1' onClick={handleBack} disabled={currentPage === 1}>
                    PREV
                </Button>

                {/* Page Number Buttons */}
                {visiblePages.map((page) => (
                    <Button
                        className='page-button mx-1'
                        key={page}
                        variant={page === currentPage ? 'primary' : 'outline-primary'}
                        onClick={() => handlePageChange(page)}
                    >
                        {page}
                    </Button>
                ))}

                {/* Next Button */}
                <Button className='nav-button mx-1' onClick={handleNext} disabled={currentPage === maxPages}>
                    NEXT
                </Button>

                <Button className='nav-button mx-1' onClick={handleLast} disabled={currentPage === maxPages}>
                    LAST
                </Button>
                <div >
                {rows.length > 0 ?
                    <Button onClick={handleExport} className="btn btn-data shadow-lg">
                        EXPORT
                    </Button>
                    :
                    <Button onClick={handleExport} className="btn btn-data disabled">
                        EXPORT
                    </Button>
                }
                </div>

            </ButtonGroup>
        </div>
    );
}

export default Pagination;
